.cart-icon {
    margin-left: 5px;
}

.badge-pill {
    background-color: $primary;
}

.dropdown-menu-cart {
    top: 75%;
    left: -140%;
}

.button-alert {
    border: none;
    background: none;
    right: 2px;
    position: absolute;
    color: gray;
}

.btn-primary {
    color: $btn_primary_text !important;
}

.navbar a.nav-link {
    padding-right: 0px !important;
    font-weight: normal !important;
}

.button-color {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    float: left;
    border-radius: 60%;
    border: $border_unselected;
}

.button-color-selected {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    float: left;
    border-radius: 60%;
    border: $border_selected;
}

.img-fluid {
    width: 100%;
    margin-bottom: 5px;
}

.longDescriptionText {
    font-size: 14px;
}

.form-group {
    padding-bottom: 15px;
}

.border-top-header {
    border-top: 2px solid $primary !important;
}

#top-header {
    display: none;
}

.navbar-nav {
    width: 100%;
    justify-content: space-between;
}

#header-cart-button {
    background: none;
    border: none;
}

#icon-user {
    font-size: 18px;
}

.text-price {
    color: $price_text;
}

.text-price-old {
    color: $price_text_old;
    text-decoration: line-through;
}

h1, h2, h3, h4, h5 {
    font-family: $font-family-base;
}

.footer-div {
    border-top: 2px solid $primary !important;
    border-bottom: 2px solid $primary !important;
}

#logo_desktop {
    text-align: center;
}

.card-box-home {
    background-color: $primary;
}

@media only screen and (max-width: 768px) {
    #logo_desktop {
        display: none;
    }

    #header-icons {
        display: none;
    }

    .logo {
        width: 150px !important;
    }

    #icon-user {
        display: none;
    }

    .card-box-home {
        min-height: auto;
    }

    .landing-img {
        display: none;
    }

    .box-img {
        display: block;
    }

    .more-info {
        font-size: 10px !important;
        text-transform: uppercase;
    }

    .specification-border {
        border-bottom: 2px solid $white;
        margin-bottom: 25px;
    }

    .no-mobile {
        display: none;
    }

    .all-items-promotion {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0 !important;
    }

    .info-box-title h1 {
        font-size: 30px !important;
    }

    .more-info-add {
        margin: 0px !important;
    }
}

@media only screen and (min-width: 767px) {
    #logo_mobile {
        display: none;
    }

    .cart-mobile {
        display: none;
    }

    #login_mobile {
        display: none;
    }

    .card-box-home {
        min-height: 150px;
    }

    .landing-img {
        display: block;
    }

    .box-img {
        display: none;
    }

    .more-info {
        font-size: 16px !important;
    }

    .see-more-products {
        margin: 0 auto;
    }
}

@media (min-width: 768px) {
    .logo {
        width: 350px !important;
    }

    .specification-border {
        border-right: 2px solid $white;
    }
}

@media (max-width: 1200px) {
    .d-flex.short-description-box {
        font-size: 10px !important;
        max-height: 150px;
    }

    .select-price {
        font-size: 12px !important;
        top: 28px !important;
    }
}

.product-title {
    font-family: $font-family-products-title;
    color: $primary !important;
    text-align: center;
    text-transform: capitalize;
    font-weight: bolder;
}

#btn-add-to-cart {
    width: -webkit-fill-available;
    width: -moz-available;
    text-transform: capitalize;
}

.no-border {
    border: none !important;
}

.img-column {
    width: -webkit-fill-available;
    width: -moz-available;
}

.specification-title {
    font-family: $font-family-products-title;
    color: $primary;
}

.product-content {
    font-size: $product-content-font-size;
    margin-top: 75px;
    margin-bottom: 75px;
    font-size: 20px;
}

.product-content h1 {
    font-weight: bolder;
    color: $primary;
    margin-bottom: 10px;
    margin-top: 75px;
}

.product-content-with-background {
    color: $white;
    padding: 55px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-size: 20px;
}

.product-content-with-background h1 {
    color: $white;
    font-weight: bolder;
}

.image-fullwidth img{
    width: 100%;
}

.product-video {
    width: -webkit-fill-available;
    width: -moz-available;
}

.divider-with-line {
    border-top: 1px solid $primary;
}

.info-box-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    margin: auto;
    height: fit-content;
}

.info-box-title h1 {
    color: $primary;
    font-family: $font-family-products-title;
    font-size: 46px;
    font-weight: bold;
    background-color: rgba(255,255,255, .8);
    width: min-content;
    padding: 5px 25px 5px 25px;
    margin: auto;
}

.info-box-subdiv {
    background-color: rgba(255, 255, 255, .8);
    width: 70%;
    margin: 0 auto;
    padding: 5%;
}

.product-price-list {
    color: $grey !important;
    font-family: $font-family-base;
    font-weight: bolder !important;
    margin-bottom: 0px !important;
}

.line-through {
    text-decoration: line-through;
}

.short-description-box {
    text-align: center;
    font-size: 14px;
}

.slim {
    font-weight: lighter;
}

.more-info {
    color: $primary !important;
    border: 2px solid $primary !important;
    text-transform: uppercase;
    min-width: 325px;
    margin: 10px;
}

.more-info-add {
    color: $white !important;
    border: 2px solid $primary !important;
    text-transform: uppercase;
    min-width: 325px;
    margin: 10px;
    font-size: 16px !important;
}

.more-info-div {
    position: absolute;
    bottom: 0;
    text-transform: uppercase;
}

@media (min-width: 993px) {
    .small-box {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .large-box {
        display: none  !important;
    }
}

#carrusel {
    float:left;
    overflow:hidden;
    height:160px;
    position:relative;
    margin-top:20px;
    margin-bottom:20px;
}

#carrusel .left-arrow {
    position:absolute;
    left:0px;
    z-index:1;
    top:10%;
    padding-right: 15px;
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 3px;
    background-color: $opacity;
}

#carrusel .right-arrow {
    position:absolute;
    right:0px;
    z-index:1;
    top:10%;
    padding-left: 15px;
    padding-top:10px;
    padding-bottom: 10px;
    background-color: $opacity;
    padding-right: 3px;
}

.carrusel {
    width:4000px;
    left:0px;
    position:absolute;
    z-index:0;
}

.carrusel>div {
    float: left;
    height: 160px;
    margin-right: 5px;
    width: 150px;
    text-align:center;
}

.carrusel img {
    cursor:pointer;
}

.title-vertical {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    display: inline-block;
    min-width: 65px;
}

.collection-vertical-title {
    border: 1px solid $primary;
    padding: 6px;
    margin-bottom: 126px !important;
    height: 300px;
    text-align: center;
    text-transform: uppercase;
}

.title-horizontal {
    padding: 5px;
    border-bottom: 2px solid $primary;
}

.product-specifications {
    background: $primary;
    padding: 45px;
    color: $white;
    font-size: 20px;
    margin-bottom: 75px;
    margin-top: 75px;
}

.product-specifications a {
    color: $white;
    text-decoration: none;
}

.product-specifications a:hover {
    color: $white;
}

.product-specifications a:visited {
    color: $white;
}

#specificationsContent {
    padding: 25px;
}

#specificationsContent ul li {
    list-style: none;
}

#specificationsContent ul li:before {
    content:"·";
    font-size:20px;
    vertical-align:middle;
    margin-right: 15px;
}

#certificatesContent {
    padding: 25px;
}

#certificatesContent ul li {
    list-style: none;
}

#certificatesContent ul li:before {
    content:"·";
    font-size:20px;
    vertical-align:middle;
    margin-right: 15px;
}

#specificationsContent h1 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#certificatesContent h1 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#specificationsContent h2 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#certificatesContent h2 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#specificationsContent h3 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#certificatesContent h3 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#specificationsContent h4 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#certificatesContent h4 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#specificationsContent h5 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#certificatesContent h5 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#specificationsContent h6 {
    color: $white !important;
    margin-bottom: 25px !important;
}

#certificatesContent h6 {
    color: $white !important;
    margin-bottom: 25px !important;
}

.table-extras {
    table-layout: fixed
}

.table-extras th {
    text-align: center;
    font-weight: bolder;
}

.table-extras th a {
    text-decoration: none;
}

#topView .text-warning {
    color: $primary !important;
}

small .text-warning {
    color: $primary !important;
}

.mainImageDiv {
    position: relative;
}

.mainImageTitle {
    position: absolute;
    padding: 5px 25px 5px 25px;
    background-color: $white;
    border: 2px solid $primary;

}

.mainImageTitle h2 {
    color: $primary !important;
    text-transform: uppercase;
    font-weight: bolder;
}

.mainImageTitle h3 {
    color: $primary !important;
    text-transform: uppercase;
    font-weight: bolder;
}

.select-price {
    position: absolute;
    top: 25px;
    right: 20px;
    font-size: 16px;
    font-weight: bolder;
}

#spanDiscountSelect {
    margin-left: 5px;
}

.force-to-hide {
    display: none !important;
}

.d-flex.force-to-hide {
    display: none !important;
}

.clock-container {
    text-align: center;
    font-size: 18px;
}

.clock-container span {
    text-align: center;
    padding: 10px;
    color: $white;
    background-color: $primary;
}

.clock-container small {
    font-size: 0.600em;
}

#titleOffer {
    text-align: center;
    display: none;
}

#titleOfferEnd {
    text-align: center;
    display: none;
}

.socials {
    text-align: center;
    font-size: 24px;
}

.socials a {
    text-decoration: none;
}

.socials a:hover {
    text-decoration: none;
}

.socials a:visited {
    text-decoration: none;
}

.owl-carousel {
    text-align: center;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    font-size: 24px !important;
}

.review {
    text-align: center;
    font-size: 14px;
}

.rating {
    font-size: 10px;
}

.form-control {
    appearance: auto !important;
    min-height: 35px;
}

.star-on {
    color: $primary;
}

.star-off {
    color: $staroff;
}

.owl-carousel.row {
    --bs-gutter-x: 0 !important;
}

.owl-nav.row {
    padding: 0 !important;
}

.owl-nav button.owl-next {
    position: absolute !important;
    right: 0 !important;
}

.owl-nav button.owl-prev {
    position: absolute !important;
    left: 0 !important;
}

button#btn-add-to-cart {
    font-size: 18px;
}

.new-cart h3 {
    font-weight: bolder;
}

.new-cart {
    font-size: 14px;
}

#addressForm button {
    font-size: 14px;
    text-transform: uppercase;
}

.resume-table {
    font-size: 18px;
}

button.final-form-button {
    font-size: 18px;
    min-width: 150px;
}

#header-icons {
    text-transform: uppercase;
}

#header-icons a {
    text-decoration: none;
}

.clock-table {
    width: 100% !important;
    margin: 0 auto;
    border-collapse: separate;
}

.clock-table td {
    background: $primary;
    color: $white;
    padding: 1px;
    width: 50%;
    font-weight: bolder;
}

.logout-pill {
    cursor: pointer;;
}

.first_price {
    font-size: 26px !important;
}

.from_price {
    font-size: 20px !important;
}

.collection-subtitle {
    font-weight: bolder;
    color: $gray-600;
    font-size: 18px;
}


.short-description-box ul {
    margin: 0 auto;
    padding: 0;
    font-size: 15px;
}

.short-description-box ul li {
    list-style-position: inside;
    margin-top: 10px;
}

.question-circle {
    background: $primary;
    border-radius: 50%;
    color: $white;
}

.table-recomendations th {
    font-weight: normal;
}

.product-details-title {
    color: $white;
}

.specification-border {
    padding: 25px;
}

.specification-border ul li {
    list-style: none;
}

.plus {
    background: none;
}

.dash {
    background: none;
    display: none;
}

.promotion-header h1 {
    text-align: center;
}


element.style {
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50
    px
    );
}

#overbox3 {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 999999;
    display: none;
}
#infobox3 {
    margin: auto;
    position: relative;
    top: 0px;
    height: auto;
    min-height: 58px;
    width: 100%;
    text-align:center;
    background-color: $primary;
    color: $white;
    padding: 15px;
}
#infobox3 p {
    font-size:14px;
    text-align:center;
}
#infobox3 p a {
    text-decoration: underline;
    color: $white;
}

.divider-small {
    max-height: 5px;
}

.finished {
    color: $price_text_old;
    font-weight: bolder;
}

.flickering {

    animation-name: flicker;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    -webkit-animation-name:flicker;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes flicker{
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@-webkit-keyframes flicker {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@keyframes flicker {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

.promotion-description {
    text-align: center;
    font-size: 16px;
    margin-bottom: 25px;
}

.center-all {
    display: flex;
    justify-content: center;
}

.thank-you-info {
    text-align: center;
    font-size: 18px;
}

.header-taxon {
    text-align: center;
    background-color: $primary;
    color: $white;
    padding: 10px;
    font-weight: bolder;
}
