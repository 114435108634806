$primary: #3c3c3b;
$btn_primary_text: #FFFFFF;
$border_selected: 2px solid $primary;
$border_unselected: 1px solid grey;
$price_text: rgba(0, 0, 0, 0.5);
$price_text_old:  rgb(255, 91, 102);
$font-family-base: 'Montserrat', sans-serif;
$font-family-products-title: 'Montserrat', serif;
$product-content-font-size: 16px;
$white: #ffffff;
$grey: #878787;
$opacity: rgba(60, 60, 59, 0.4);
$staroff: silver;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
)
