.navbar a.nav-link {
    text-transform: uppercase;
    color: $headings-color;
    font-weight: 500;

    &:hover {
        color: $primary;
    }
}

@include media-breakpoint-up(lg) {
    .dropdown-menu .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: 0;
    }
}

.logo {
    @include media-breakpoint-up(md) {
        width: 160px;
    }

    @include media-breakpoint-down(sm) {
        width: 130px;
    }
}

.main-content > :first-child:not(nav):not(.nav) {
    margin-top: map-get($spacers, 4);
}


.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .7);
}

.loadable.loading .loading-overlay { display: flex; }


label.required {
    &::after {
        content: '*';
        display: inline-block;
        padding-left: 4px;
        color: $danger;
    }
}

.btn-icon {
    padding-right: 5px;
    padding-left: 5px;
    //font-size: $font-size-lg;
}
